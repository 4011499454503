@import url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'FontAwesome';
  src: url('../fonts/fontawesome-webfont.eot');
  src: url('../fonts/fontawesome-webfont.woff') format('woff'), url('../fonts/fontawesome-webfont.woff2') format('woff2'),
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), url('../fonts/fontawesome-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Font Awesome 5 Brand Regular';
  src: url('../fonts/fa-brands-400.eot') format('eot');
  src: url('../fonts/fa-brands-400.woff') format('woff'), url('../fonts/fa-brands-400.woff2') format('woff2'),
    url('../fonts/fa-brands-400.ttf') format('ttf'), url('../fonts/fa-brands-400.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Font Awesome 5 Free Solid';
  src: url('../fonts/fa-solid-900.eot') format('eot');
  src: url('../fonts/fa-solid-900.woff') format('woff'), url('../fonts/fa-solid-900.woff2') format('woff2'),
    url('../fonts/fa-solid-900.ttf') format('ttf'), url('../fonts/fa-solid-900.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Font Awesome 5 Free Regular';
  src: url('../fonts/fa-regular-400.eot') format('eot');
  src: url('../fonts/fa-regular-400.woff') format('woff'), url('../fonts/fa-regular-400.woff2') format('woff2'),
    url('../fonts/fa-regular-400.ttf') format('ttf'), url('../fonts/fa-regular-400.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

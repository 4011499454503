/* =============================
        Account Section
============================= */
.AccountSec {
  position: relative;
  padding: 70px 0;
  background: nth($color, 3);
  &::before {
    content: '';
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    height: 1px;
    background: nth($color, 4);
  }
  @include Media(767) {
    padding: 50px 0;
  }
  .ThanksMsg {
    position: relative;
    text-align: center;
    h2 {
      @include Font(3, 700, 4);
      margin-bottom: 30px;
    }
    p {
      @include Font(1, 400, 1);
      margin-bottom: 30px;
    }
  }
  .AccountOuterDiv {
    position: relative;
    .AccountPagUl {
      position: relative;
      margin-bottom: 50px;
      li {
        position: relative;
        padding: 0 30px;
        @include Media(479) {
          padding: 0 20px;
        }
        &:last-child::before {
          display: none;
        }
        &::before {
          content: '\f101';
          font-family: FontAwesome;
          position: absolute;
          top: 0;
          right: -12px;
          font-size: 3rem;
          color: nth($color, 1);
          @include Media(767) {
            font-size: 2rem;
          }
          @include Media(479) {
            font-size: 1.8rem;
          }
        }
        &.active {
          span {
            color: nth($color, 1);
          }
        }
      }
      span {
        padding: 2px 25px;
        @include Font(3, 700, 3);
        background: nth($color, 4);
        clip-path: polygon(5% 0, 100% 0, 95% 100%, 0% 100%);
        @include Media(767) {
          padding: 2px 15px;
          font-size: 2rem;
        }
        @include Media(479) {
          font-size: 1.8rem;
        }
      }
    }
    .AccountForm {
      position: relative;
      .Title {
        position: relative;
        margin-bottom: 30px;
        border-bottom: 1px solid nth($color, 4);
        h2 {
          text-align: center;
          @include Font(4, 700, 4);
          @include Media(767) {
            font-size: 3rem;
            padding-bottom: 10px;
          }
          @include Media(479) {
            font-size: 2rem;
            padding-bottom: 10px;
          }
        }
        p {
          text-align: center;
          @include Font(1.2, 400, 4);
          margin-bottom: 30px;
          @include Media(479) {
            font-size: 1rem;
            margin-bottom: 10px;
          }
        }
      }
      .FormTab {
        form {
          position: relative;
          &.OtherForm {
            input {
              position: relative;
              width: 100%;
              background: nth($color, 3);
              border: 1px solid nth($color, 4);
              padding: 5px 15px;
              @include Font(1, 400, 1);
              @include BorRadius(5px);
              &::placeholder {
                color: nth($color, 1);
              }
              @include Media(479) {
                font-size: 0.85rem;
              }
            }
            a {
              cursor: pointer;
              margin: 0 5px;
              @include Font(1, 400, 1);
              @include Media(479) {
                font-size: 0.85rem;
              }
            }
            .PhoneInput {
              padding: 0 15px;
              border: 1px solid nth($color, 4);
              @include BorRadius(5px);
              .PhoneInputCountrySelectArrow,
              .PhoneInputCountryIcon {
                color: nth($color, 1);
              }
              .PhoneInputInput {
                border: 0;
              }
            }
            textarea {
              @extend input;
              height: 100px;
            }
            .CusSelect {
              @extend input;
              padding: 8px 15px !important;
              cursor: pointer;
            }
          }
          .CheckPos {
            position: relative;
            .MuiFormControl-root {
              margin: 0 !important;
            }
            button {
              @include Flex(center, center);
              position: absolute;
              top: 0px;
              right: 0px;
              width: 30px;
              height: 100%;
              border: 0;
              padding: 0;
              background: nth($color, 4);
              border-top-left-radius: 0px;
              border-bottom-left-radius: 0px;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
              cursor: pointer;
              z-index: 0;
              &:focus {
                outline: none;
              }
              svg {
                color: nth($color, 1);
              }
            }
            .CheckPassword {
              @include Flex(center, center);
              position: absolute;
              top: 18px;
              right: -8px;
              width: 30px;
              height: 60%;
              border: 0;
              background: nth($color, 4);
              cursor: pointer;
              z-index: 0;
              &:focus {
                outline: none;
              }
              .icon {
                color: nth($color, 1);
              }
            }
          }
          textarea {
            @extend input;
            height: 100px;
          }
          .CusSelect {
            @extend input;
            cursor: pointer;
          }
          .Eye {
            @include Flex(center, center);
            position: absolute;
            top: 0;
            right: 16px;
            width: 30px;
            height: 100%;
            border: 0;
            background: nth($color, 4);
            cursor: pointer;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            &:focus {
              outline: none;
            }
            .icon {
              color: nth($color, 1);
            }
          }
          .Role {
            position: relative;
            p {
              @include Font(1, 400, 1);
              @include Media(479) {
                font-size: 0.85rem;
              }
            }
            input {
              width: auto;
              margin-left: 15px;
            }
            label {
              cursor: pointer;
              margin: 0 5px;
              @include Font(1, 400, 4);
              @include Media(479) {
                font-size: 0.85rem;
              }
            }
          }
          input::placeholder {
            color: nth($color, 1) !important;
          }
          .Overlay {
            position: relative;
            background: nth($color, 3);
            border: 1px solid nth($color, 4);
            @include Font(1, 400, 1);
            @include BorRadius(5px);
            @include Media(479) {
              font-size: 0.85rem;
            }
            span {
              text-align: left;
              width: calc(100% - 150px);
              padding: 3px 12px;
            }
            button {
              border: 0;
              padding: 5px 30px;
              @include Font(1, 400, 1);
              background: nth($color, 4);
              @include Media(479) {
                padding: 5px 20px;
                font-size: 0.85rem;
              }
            }
          }
          .PaymentUl {
            position: relative;
            margin: 30px 0 80px;
            li {
              position: relative;
              padding: 0 30px;
              .PaymentLink {
                position: relative;
                text-align: center;
                @include Tran(0.4s, ease-in-out);
                @include Media(767) {
                  margin-bottom: 50px;
                }
                &::before {
                  content: '';
                  position: absolute;
                  bottom: -25px;
                  left: -10px;
                  width: 130px;
                  height: 90px;
                  z-index: 0;
                  background: nth($color, 1);
                  clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
                }
                &::after {
                  content: '';
                  position: absolute;
                  bottom: -22px;
                  left: -8px;
                  width: 124px;
                  height: 84px;
                  z-index: 0;
                  background: nth($color, 3);
                  clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
                }
                &:hover {
                  p {
                    color: nth($color, 4);
                  }
                  .AmountDiv {
                    background: nth($color, 4);
                    span {
                      color: nth($color, 1);
                    }
                  }
                }
                p {
                  @include Font(2, 300, 1);
                  z-index: 9;
                  margin-bottom: 15px;
                  @include Tran(0.4s, ease-in-out);
                }
                .AmountDiv {
                  position: relative;
                  padding: 10px 40px;
                  background: nth($color, 5);
                  clip-path: polygon(15% 0, 100% 0, 85% 100%, 0% 100%);
                  @include Tran(0.4s, ease-in-out);
                  z-index: 9;
                  small {
                    display: block;
                    margin-bottom: 30px;
                    @include Font(0.75, 400, 3);
                  }
                  span {
                    @include Tran(0.4s, ease-in-out);
                    display: block;
                    @include Font(2, 300, 4);
                  }
                }
              }
            }
          }
          .PaymentDescOl {
            position: relative;
            margin-bottom: 50px;
            li {
              margin-bottom: 5px;
              @include Font(1, 400, 1);
              @include Media(479) {
                font-size: 0.85rem;
              }
            }
          }
          .PaymentBtn {
            width: 410px;
            height: 40px;
            position: relative;
            border: 0;
            background-color: transparent;
            @include Font(1, 400, 1);
            &:focus {
              outline: none;
            }
            &::before {
              content: '';
              position: absolute;
              top: 0;
              right: -1px;
              width: 42px;
              height: 42px;
              background: nth($color, 3);
              clip-path: polygon(100% 0, 100% 100%, 0% 100%);
            }
            @include Media(479) {
              width: 80%;
              padding: 0 0 0 50px;
              font-size: 0.8rem;
            }
          }
          .SubmitBtn {
            border: 0;
            padding: 10px 50px;
            text-transform: uppercase;
            background: nth($color, 4);
            @include Font(1, 300, 1);
            clip-path: polygon(15% 0, 100% 0%, 85% 100%, 0% 100%);
            @include Media(575) {
              padding: 8px 30px;
              font-size: 0.85rem;
            }
          }
        }
      }
      .PhoneInput {
        padding: 20px 15px 0;
        border-bottom: 1px solid nth($color, 4);
        .PhoneInputCountrySelectArrow,
        .PhoneInputCountryIcon {
          color: nth($color, 1);
        }
        .PhoneInputInput {
          position: relative;
          width: 100%;
          background: nth($color, 3);
          border: 0;
          padding: 5px 15px;
          @include Font(1, 400, 1);
          &::placeholder {
            color: nth($color, 1);
          }
          @include Media(479) {
            font-size: 0.85rem;
          }
        }
      }
    }
  }
}

.CropModal {
  h5 {
    margin-bottom: 0;
    @include Font(1.5, 700, 4);
  }
  .CroppedDiv {
    position: relative;
    padding: 25px;
    background: nth($color, 3);
    .ReactCrop__image {
      width: auto;
      height: 300px;
    }
    button {
      border: 0;
      display: block;
      margin: 15px 0;
      padding: 10px 50px;
      text-transform: uppercase;
      background: nth($color, 4);
      @include Font(1, 300, 1);
      clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0% 100%);
      @include Media(575) {
        padding: 8px 30px;
        font-size: 0.85rem;
      }
    }
    input {
      position: relative;
      width: 100%;
      margin-bottom: 30px;
      background: nth($color, 3);
      border: 1px solid nth($color, 4);
      padding: 5px 15px;
      @include Font(1, 400, 1);
      @include BorRadius(5px);
      &::placeholder {
        color: nth($color, 1);
      }
      @include Media(479) {
        font-size: 0.85rem;
      }
    }
    .PreviewImg {
      position: relative;
      h4 {
        margin-bottom: 15px;
        @include Font(1.5, 400, 4);
      }
    }
  }
}

div[role='button'],
.MuiInputBase-root,
.MuiTextField-root {
  width: 100% !important;
}

.MuiInputBase-input {
  padding: 8px 10px !important;
}

.Appear {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
}

.MuiIconButton-colorSecondary,
.MuiRadio-root {
  color: nth($color, 1) !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: nth($color, 4) !important;
}

.MuiFormControlLabel-label,
.MuiInputBase-input,
.MuiFormLabel-root {
  color: nth($color, 1) !important;
}
.MuiInput-underline:after,
.MuiInput-underline:before {
  display: none;
}

.MuiSelect-nativeInput {
  background: transparent;
}

.Appear {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
}

.ResetMessageDiv {
  position: relative;
  padding: 15px;
  p {
    text-align: center;
    line-height: 28px;
    @include Font(1.2, 400, 1);
    @include Media(575) {
      font-size: 1rem;
    }
  }
}

/* =============================
        HTML & BODY
============================= */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: 1rem;
  overflow-x: hidden;
  position: relative;
}

body {
  width: 100vw;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  font-family: 'Arvo', sans-serif !important;
  font-weight: 400;
  font-size: 1rem;
  color: nth($color, 3);
  position: relative;
}

.DBlock,
header,
section,
footer {
  float: left;
  width: 100%;
  height: 100%;
  display: block;
}

.DFlex {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  letter-spacing: normal;
  text-transform: none;
  line-height: normal;
  margin-bottom: 0;
}

label {
  margin-bottom: 0;
}

ul,
ol {
  list-style: none;
  margin-bottom: 0;
}

.FocusNone,
button:focus,
a:focus,
.btn,
textarea:focus,
input:focus,
select:focus {
  outline: none !important;
}

.TransAni {
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

img {
  max-width: 100%;
  height: auto;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

.TitleStyle {
  position: relative;
  width: auto;
  display: inline-block;
  margin-bottom: 30px;
  padding: 0 140px;
  @include Media(991) {
    padding: 0 65px;
  }
  @include Media(575) {
    padding: 0 55px 0 0;
  }
  &::before {
    content: '';
    position: absolute;
    top: 40%;
    left: 0;
    width: 115px;
    height: 20px;
    background: nth($color, 4);
    clip-path: polygon(25% 0, 100% 0%, 100% 100%, 0 100%);
    @include Media(991) {
      width: 50px;
      height: 10px;
    }
    @include Media(575) {
      display: none;
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: 40%;
    right: 0;
    width: 115px;
    height: 20px;
    background: nth($color, 4);
    clip-path: polygon(0 0, 100% 0%, 70% 100%, 0 100%);
    @include Media(991) {
      width: 50px;
      height: 10px;
    }
    @include Media(575) {
      width: 40px;
    }
  }
  h2 {
    @include Font(2.8, 900, 1);
    @include Media(1366) {
      font-size: 2.3rem;
    }
    @include Media(991) {
      font-size: 2rem;
    }
    @include Media(575) {
      font-size: 1.5rem;
    }
  }
}

.TitlePort {
  position: relative;
  padding: 8px 20px 8px 100px;
  z-index: 9;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    z-index: -1;
    clip-path: polygon(8% 0, 100% 0, 92% 100%, 0% 100%);
    background: nth($color, 3);
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 5%;
    width: 12%;
    height: 100%;
    z-index: -1;
    clip-path: polygon(50% 0, 100% 0, 50% 100%, 0% 100%);
    background: nth($color, 4);
  }
  @include Media(991) {
    padding: 10px 30px 10px 70px;
  }
  @include Media(767) {
    padding: 10px 30px 10px 60px;
  }
  @include Media(575) {
    padding: 10px 30px 10px 70px;
  }
  p {
    text-transform: capitalize;
    margin-bottom: 0;
    @include Font(1, 400, 1);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.MuiInputBase-input {
  background-color: transparent !important;
}

//Loader Styling
/* MULTI SPINNER */
.ParentLoader {
  // position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  z-index: 9999;
  &.MinHeight {
    .LoaderDiv {
      min-height: auto;
    }
  }
  .LoaderDiv {
    min-height: 500px;
  }
}

.DevnDropDown {
  .ParentLoader {
    position: absolute;
  }
}

.multi-spinner-container {
  width: 150px;
  height: 150px;
  position: relative;
  margin: 30px auto;
  overflow: hidden;
}

.multi-spinner {
  position: absolute;
  width: calc(100% - 9.9px);
  height: calc(100% - 9.9px);
  border: 5px solid transparent;
  border-top-color: nth($color, 4);
  border-radius: 50%;
  -webkit-animation: spin 5s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
  animation: spin 5s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.height-80 {
  height: 80px !important;
}

.FloatCarousel {
  .item {
    @include Media(575) {
      height: 350px !important;
    }
  }
}

.ant-card-body {
  padding: 0 !important;
}

.modal-dialog {
  margin: 50px auto 100px;
}

/* =============================
        Policy Section
============================= */
.PolicyTextSec {
  position: relative;
  background: nth($color, 3);
  padding: 50px 0;
  .PolicyArea {
    position: relative;
    .Title {
      border-bottom: 1px solid nth($color, 4);
      padding-bottom: 30px;
      margin-bottom: 30px;
      @include Media(575) {
        padding-bottom: 20px;
      }
      &.AboutTitle {
        h2 {
          font-size: 2.5rem;
          @include Media(575) {
            font-size: 1.5rem;
          }
        }
      }
      h2 {
        @include Font(4, 900, 4);
        @include Media(575) {
          font-size: 1.8rem;
        }
      }
    }
    .TextDiv {
      position: relative;
      h4 {
        margin-bottom: 25px;
        @include Font(1.5, 700, 4);
        @include Media(575) {
          font-size: 1.2rem;
        }
      }
      p {
        margin-bottom: 20px;
        line-height: 22px;
        @include Font(1, 400, 1);
        @include Media(575) {
          font-size: 0.85rem;
        }
        span {
          font-weight: 700;
          color: nth($color, 4);
        }
      }
    }
    .TextFooter {
      border-top: 1px solid nth($color, 4);
      padding: 15px 0;
      h4 {
        @include Font(1.2, 700, 4);
      }
    }
  }
}

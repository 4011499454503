/* =============================
        Blogs Section
============================= */
.BlogsSec {
  position: relative;
  padding: 100px 0 150px;
  background: nth($color, 3);
  @include Media(991) {
    padding: 50px 0 100px;
  }
  &::before {
    content: '';
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    height: 1px;
    background: nth($color, 4);
  }
  .BlogsOuterDiv {
    position: relative;
    h2 {
      margin-bottom: 30px;
      @include Font(3, 700, 4);
      @include Media(991) {
        font-size: 2rem;
        margin-bottom: 30px;
      }
      @include Media(479) {
        font-size: 1.5rem;
        margin-bottom: 30px;
      }
    }
    .BlogSearch {
      position: relative;
      margin-bottom: 30px;
      form {
        width: 50%;
        position: relative;
        background: nth($color, 5);
        clip-path: polygon(5% 0, 100% 0%, 97% 100%, 0% 100%);
        @include Media(767) {
          width: 70%;
        }
        @include Media(575) {
          width: 100%;
          margin-bottom: 15px;
        }
        label {
          border: 0;
          padding: 5px 30px;
          text-transform: uppercase;
          background: #839aa1;
          @include Font(1, 300, 1);
          clip-path: polygon(15% 0, 100% 0%, 85% 100%, 0% 100%);
        }
        input {
          width: calc(100% - 180px);
          border: 0;
          padding: 5px 30px;
          text-transform: uppercase;
          background: nth($color, 5);
          @include Font(1, 300, 3);
        }
        button {
          border: 0;
          padding: 5px 15px;
          text-transform: uppercase;
          background: nth($color, 4);
          @include Font(1, 300, 1);
          clip-path: polygon(25% 0, 100% 0%, 85% 100%, 0% 100%);
        }
      }
      .PaginationUl {
        width: auto;
        position: relative;
        @include Media(575) {
          width: 100%;
          justify-content: flex-end;
        }
        a {
          @include Flex(center, center);
          width: 40px;
          height: 40px;
          background: nth($color, 4);
          clip-path: polygon(10% 0, 100% 0, 90% 100%, 0% 100%);
          .icon {
            font-size: 1.5rem;
            color: nth($color, 1);
          }
        }
      }
    }
    .BlogsDiv {
      position: relative;
      height: auto;
      padding: 30px 0;
      border-top: 2px solid nth($color, 4);
      h5 {
        margin-bottom: 15px;
        @include Font(1.2, 600, 1);
      }
      .BlogImg {
        position: relative;
        height: auto;
        overflow: hidden;
        padding: 10px;
        background: nth($color, 1);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        .Overlay {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.5);
        }
        img {
          display: block;
          margin: 0 auto;
          width: auto;
          height: 300px;
        }
      }
      .BlogFooter {
        position: relative;
        background: linear-gradient(to right, nth($color, 4) 0%, nth($color, 4) 70%, nth($color, 2) 70%, nth($color, 2) 100%);
        padding: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        overflow: hidden;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 45%;
          height: 100%;
          z-index: 0;
          background: nth($color, 2);
          clip-path: polygon(100% 0, 100% 100%, 0 100%, 25% 0);
          @include Media(1366) {
            right: -1px;
            height: 102%;
          }
        }
        p {
          z-index: 9;
          margin-bottom: 0;
          @include Font(1, 400, 1);
        }
        .BlogText {
          position: relative;
          small {
            @include Font(0.85, 300, 3);
          }
          p {
            margin-bottom: 0;
            @include Font(1, 400, 1);
          }
        }
        a {
          z-index: 99;
          margin-bottom: 0;
          text-transform: uppercase;
          @include Font(1, 300, 1);
        }
      }
    }
  }
}

/* =============================
Blogs Details - Home Section
============================= */
.BlogDetailsHomeSec {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-bottom: 10px solid nth($color, 4);
  height: 450px;
  @include Media(767) {
    height: 350px;
  }
}

/* =============================
Blogs Details - Details Section
============================= */
.BlogDetailSec {
  position: relative;
  padding: 30px 0 50px;
  background: nth($color, 3);
  @include Media(767) {
    overflow-x: hidden;
  }
  .BlogDetailDiv {
    position: relative;
    .Title {
      position: relative;
      margin: 0px 0 30px;
      h3 {
        margin-bottom: 30px;
        @include Font(1.5, 700, 4);
      }
      h5 {
        line-height: 32px;
        @include Font(1.1, 600, 4);
      }
    }
    .BlogDetailsTextDiv {
      position: relative;
      h6 {
        position: relative;
        width: auto;
        display: inline-block;
        margin-bottom: 10px;
        @include Font(1.2, 600, 1);
        &::before {
          content: '';
          position: absolute;
          top: 45%;
          right: -115px;
          width: 100px;
          height: 2px;
          background: nth($color, 4);
        }
      }
      small {
        margin-bottom: 30px;
        display: block;
        @include Font(0.85, 400, 4);
      }
      p {
        margin-bottom: 30px;
        @include Font(1, 400, 1);
      }
    }
    .BlogDetailsImgDiv {
      position: relative;
      text-align: center;
      .ImgDiv {
        position: relative;
        float: none;
        width: 250px;
        height: 300px;
        margin: 0 auto 30px;
        background: nth($color, 4);
        padding: 15px;
        clip-path: polygon(6% 0, 100% 0%, 94% 100%, 0% 100%);
        @include Media(767) {
          width: 50%;
          float: none;
          margin: 0 auto 30px;
        }
        @include Media(575) {
          width: 70%;
          float: none;
          margin: 0 auto 30px;
        }
        &::before {
          content: '';
          position: absolute;
          top: 1%;
          left: 1%;
          width: 98%;
          height: 98%;
          background: nth($color, 3);
          z-index: -1;
          clip-path: polygon(6% 0, 100% 0%, 94% 100%, 0% 100%);
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          clip-path: polygon(6% 0, 100% 0%, 94% 100%, 0% 100%);
        }
      }
      h5 {
        width: 100%;
        display: block;
        margin-bottom: 30px;
        @include Font(1, 600, 4);
      }
    }
    .PaginationUl {
      position: relative;
      padding: 50px 0;
      border-bottom: 1px solid nth($color, 4);
      li {
        @include Flex(center, center);
        width: auto;
        cursor: pointer;
        @include Font(1, 400, 1);
      }
      p {
        margin-bottom: 0;
      }
      span {
        @include Flex(center, center);
        width: 40px;
        height: 40px;
        margin: 0 10px;
        background: nth($color, 4);
        clip-path: polygon(10% 0, 100% 0, 90% 100%, 0% 100%);
        .icon {
          font-size: 1.5rem;
          color: nth($color, 1);
        }
      }
      .text {
        @include Media(575) {
          display: none;
        }
      }
    }
  }
}

$color: #ffffff, #000000, #2c2c2c, #e8b239, #e5e9e8;

@mixin Flex($var1, $var2) {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: $var1;
  align-items: $var2;
}

@mixin Padding($var1, $var2) {
  padding: $var1 + rem $var2 + rem;
}

@mixin BG($var) {
  background: url('../images/#{$var}') no-repeat center/cover;
}

@mixin Media($var) {
  @media only screen and (max-width: $var + px) {
    @content;
  }
}

@mixin MediaHeight($var) {
  @media only screen and (max-height: $var + px) {
    @content;
  }
}

@mixin Absolute($var1, $var2, $var3, $var4, $var5, $var6, $var7) {
  content: $var1;
  position: absolute;
  top: $var2;
  bottom: $var3;
  left: $var4;
  right: $var5;
  width: $var6;
  height: $var7;
}

@mixin Tran($var1, $var2) {
  transition: all $var1 $var2;
  -webkit-transition: all $var1 $var2;
  -moz-transition: all $var1 $var2;
  -ms-transition: all $var1 $var2;
  -o-transition: all $var1 $var2;
}

@mixin TransForm($var) {
  transform: $var;
  -webkit-transform: $var;
  -moz-transform: $var;
  -ms-transform: $var;
  -o-transform: $var;
}

@mixin BorRadius($var) {
  border-radius: $var;
  -webkit-border-radius: $var;
  -moz-border-radius: $var;
  -ms-border-radius: $var;
  -o-border-radius: $var;
}

@mixin Img($var) {
  width: 100%;
  height: 100%;
  object-fit: $var;
}

@mixin Font($var1, $var2, $var3) {
  font-size: $var1 + rem;
  font-weight: $var2;
  @if $var3>0 {
    color: nth($color, $var3);
  }
}
@mixin FontRal($var1, $var2, $var3) {
  font-family: 'Raleway', sans-serif !important;
  font-size: $var1 + rem;
  font-weight: $var2;
  @if $var3>0 {
    color: nth($color, $var3);
  }
}

/* =============================
        Footer Section
============================= */
.FooterSec {
  position: relative;
  padding: 30px 0;
  background: nth($color, 5);
  @include Media(767) {
    padding: 30px 0;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    height: 100%;
    background: nth($color, 3);
    z-index: 0;
    clip-path: polygon(100% 0, 80% 100%, 0 100%, 0 0);
    @include Media(1600) {
      width: 30%;
    }
    @include Media(1366) {
      width: 35%;
    }
    @include Media(991) {
      width: 40%;
    }
    @include Media(800) {
      width: 44%;
      clip-path: polygon(100% 0, 90% 100%, 0 100%, 0 0);
    }
    @include Media(767) {
      clip-path: none;
      top: auto;
      bottom: 0;
      width: 100%;
      height: 100px;
      clip-path: polygon(100% 0, 80% 100%, 0 100%, 0 0);
    }
    @include Media(479) {
      clip-path: none;
      height: 150px;
      padding: 30px 0 20px;
    }
  }
  .FooterOuterDiv {
    position: relative;
    max-width: 1366px;
    float: none;
    margin: 0 auto;
    .FooterDiv {
      position: relative;
      text-align: center;
      img {
        display: block;
        width: 150px;
        margin: 0 auto 20px;
        @include Media(991) {
          width: 150px;
        }
      }
      .FooterSocial {
        margin-bottom: 0;
        position: relative;
        a {
          @include Flex(center, center);
          width: 40px;
          height: 40px;
          background: nth($color, 4);
          @include BorRadius(50%);
          @include Media(767) {
            margin: 5px 15px;
          }
          .icon {
            font-size: 1.2rem;
            color: nth($color, 3);
          }
        }
      }
      .LinkUl {
        position: relative;
        justify-content: flex-end !important;
        @include Media(767) {
          justify-content: center !important;
        }
        a {
          position: relative;
          display: block;
          text-transform: uppercase;
          font-style: italic;
          padding: 0 15px;
          @include FontRal(1.2, 700, 3);
          @include Media(479) {
            font-size: 1rem;
          }
          &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 1px;
            height: 100%;
            background: nth($color, 4);
            transform: rotate(30deg);
          }
          &.NoBefore::before {
            display: none;
          }
        }
      }
      .FooterForm {
        position: relative;
        margin: 20px;
        width: 90%;
        margin-left: auto;
        @include Media(1199) {
          width: 90%;
        }
        @include Media(991) {
          width: 100%;
        }
        input {
          position: relative;
          width: 75%;
          background: #c7cac9;
          padding: 15px;
          border: 0;
          @include Font(1, 600, 3);
          @include Media(479) {
            width: 65%;
            padding: 10px 15px;
            font-size: 0.85rem;
          }
        }
        button {
          width: 25%;
          margin-left: -30px;
          text-align: center;
          background: nth($color, 4);
          padding: 15px;
          border: 0;
          text-transform: uppercase;
          @include Font(1, 600, 1);
          @include Media(800) {
            padding: 17px 10px;
            font-size: 0.85rem;
          }
          @include Media(479) {
            width: 35%;
            padding: 11.5px 5px;
            font-size: 0.75rem;
          }
        }
      }
      .LinkOl {
        position: relative;
        @include Media(767) {
          margin-bottom: 30px;
        }
        a {
          margin: 0 15px;
          @include Font(1, 300, 3);
          @include Media(479) {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}

/* =============================
    Copy Right Section
============================= */
.CprytDiv {
  position: relative;
  padding: 15px;
  background: nth($color, 4);
  img {
    width: 40px;
    margin-right: 15px;
    @include Media(479) {
      width: 40px;
      margin: 0 15px 15px 0;
    }
  }
  p {
    margin-bottom: 0;
    @include Font(0.85, 600, 3);
    @include Media(479) {
      font-size: 0.75rem;
    }
    a {
      @extend p;
    }
  }
}
